import Cookie from 'js-cookie';
import addDays from 'date-fns/addDays';
import { useRouter } from 'next/router'

enum Cookies {
  DEFAULTURL = 'DEFAULTURL',
  REDIRECTURL = 'REDIRECTURL',
  SERIESBTREF = 'SERIESBTREF',
  BTREF = 'BTREF'
}

function SetDefaultCookie(redirectUrl:string, hostUrl:string) {
	const URL = redirectUrl ? (hostUrl + redirectUrl) : hostUrl
	Cookie.set(Cookies.DEFAULTURL, URL, {
	  expires: addDays(Date.now(), 1),
	  secure: true,
	  path: '/',
	});
}

function getRedirectQuery() {
  const router = useRouter()

  return router.query.redirect
}

function setRedirectQuery(hostUrl:string) {
	const redirectUrl = getRedirectQuery();

	Cookie.set(Cookies.REDIRECTURL, `${hostUrl}${redirectUrl}`, {
		expires: addDays(Date.now(), 1),
		secure: true,
		path: '/'
	});
}

export default function referralCookie(hostUrl: string, redirectUrl?:string ) {

  const getRedirectUrl = getRedirectQuery();

  const seriesCookies = Cookie.get(Cookies.SERIESBTREF);
  const portalCookies = Cookie.get(Cookies.BTREF);
  let referralCookie = '';

  if ( getRedirectUrl  !== undefined ) {
	setRedirectQuery(hostUrl);
	referralCookie = Cookies.REDIRECTURL
  } else if (seriesCookies) {
    referralCookie = Cookies.SERIESBTREF
  } else if(portalCookies) {
    referralCookie = Cookies.BTREF
  } else {
	SetDefaultCookie(redirectUrl, hostUrl);
	referralCookie = Cookies.DEFAULTURL
  }

  return referralCookie;
}
