import React, { FC, useState, useEffect } from 'react';
import Script from 'next/script';
import ReferralCookie from '../../libs/referralCookie';
import Skeleton from './Skeleton'

interface GlobalAuthProps {
  type: 'WP-Registration' | 'WP-Login' | 'ResetPassword';
  redirectUrl?: string;
  hostUrl: string
}

const _GlobalAuth: FC<GlobalAuthProps> = ({ type = 'WP-Registration', redirectUrl = null, hostUrl }) => {
  const getReferralCookie = ReferralCookie(hostUrl, redirectUrl)
  const [storedReferralCookie, setReferralCookie] = useState<string | undefined>()

  const [isLoading, setLoading] = useState( true )

  useEffect(() => {
    setReferralCookie(getReferralCookie)

    const smartformComponent = document.querySelector('globalauth-helpercomponent')
		if (smartformComponent) {
			smartformComponent.addEventListener('globalauthMessage', handleEvent)
		}

  }, [getReferralCookie])

  const handleEvent = (event) => {
		if (event?.detail?.type === "globalauth-mounted" ) {
			setLoading(false)
		}
	}

  return (
    <>
      <Script
        src={
			hostUrl +
          `/globalauth-helpercomponent/globalauth-helpercomponent.esm.js`
        }
        type="module"
      />

      {(isLoading) &&
        <Skeleton formType={type} />
      }

      <globalauth-helpercomponent
        form-style='{"primaryColor": "#003CB2", "secondaryColor": "#003CB2"}'
        comp-type={type}
        language="en-US"
        referral-cookie-name={storedReferralCookie}
        envvalue={process.env.BASE_ENV}
      />
    </>
  );
};

export default _GlobalAuth;
