import React from 'react'
import styles from './index.module.scss';
import GlobalAuth from '../../components/GlobalAuth';
import MetaHead from '../../components/MetaHead/MetaHead';
import fetchUserProfile from '../../libs/fetchUserProfile'
import nextCookies from 'next-cookies'
import { setCookie, deleteCookie } from 'cookies-next';

export async function getServerSideProps(context) {
	const { req, res } = context;

	const { BTSESSION, SERIESBTREF } = nextCookies(context)

	const response = await fetchUserProfile(BTSESSION)

	const hostUrl = global.process.env.HOST;

	const domain = hostUrl?.replace("https://www", "");

	let preAuth = Boolean(response?.preAuth);

	if (preAuth) {
		// Remove BTSESSION cookie.
		deleteCookie('BTSESSION', { req, res, path: '/', domain: domain });
	}
	if ( SERIESBTREF !== '' ) {
		// Expires in 30mins
		setCookie('SERIES_REF_URL', hostUrl + '/login', { req, res, maxAge: 1800, path: '/', domain: domain });
	}

	return {
		props: {
			userSession: response,
			seriesBtref: SERIESBTREF ? SERIESBTREF : '',
			hostUrl: hostUrl,
			staticAssetUrl: global.process.env.STATIC_ASSETS_CDN_URL
		}, // will be passed to the page component as props
	}
}

export default function Login({ userSession, seriesBtref, hostUrl , staticAssetUrl}) {

	return (

		<>
			<MetaHead title='Login - BrightTALK' pageName='Login' hostUrl={hostUrl} staticAssetUrl={staticAssetUrl} />
			<div className={styles.page}>
				<GlobalAuth
					type="WP-Login"
					hostUrl={hostUrl}
					redirectUrl={(userSession?.isLoggedIn && userSession?.preAuth != 'true') ? '/mybrighttalk/my-feed' : null}
				/>
			</div>
		</>
	);
}
